import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NewHome = ({data}) => (
  <Layout>
    <SEO title="New Home" />
     <script dangerouslySetInnerHTML={{
          __html: `
                  var src = process.env.GATSBY_DRUPAL_BASE_URL;
                  console.log('Drupal Source ' + src);
              `,
        }}
      />
    <div> { data.allNodePage.edges.map(({ node }) => ( 
      <div> 
        <h3>{ node.title }</h3> 
        <div dangerouslySetInnerHTML={{ __html: node.body.value }} /> </div> 
        ))} 
      </div>

  </Layout>
)
export const query = graphql` 
  query {
    allNodePage {  
      edges { 
        node { 
          id title body { value format processed summary }
          } 
      } 
    }  
  } `

export default NewHome
